<template>
    <div class="tasklist">
        <div class="tasklist-inner">
            <!-- ==============================顶部tab项目状态=================================  -->
            <el-menu :default-active="statusTabActiveIndex" class="tatus-nav" mode="horizontal">
                <el-menu-item index="0">
                    <router-link to="/user/task/list?status=0">所有项目</router-link>
                </el-menu-item>

                <el-menu-item index="2" v-if="userInfo && userInfo.info.user_type == 2">
                    <router-link to="/user/task/list?status=2">审核中</router-link>
                </el-menu-item>

                <el-menu-item index="37">
                    <router-link to="/user/task/list?status=37">进行中</router-link>
                </el-menu-item>

                <el-menu-item index="9">
                    <router-link to="/user/task/list?status=9">调解中</router-link>
                </el-menu-item>

                <el-menu-item index="8">
                    <router-link to="/user/task/list?status=8">已完成</router-link>
                </el-menu-item>

                <el-menu-item index="10">
                    <router-link to="/user/task/list?status=10">已结束</router-link>
                </el-menu-item>

                <el-menu-item index="1" class="before-line" v-if="userInfo.info.user_type==2">
                    <router-link to="/user/task/list?status=1">草稿箱</router-link>
                    <span class="u-tool-tip" v-if="cgxCount>0">您有未发布的项目，请点击草稿箱查看</span>
                </el-menu-item>
            </el-menu>

            <div class="task-list-items-wraper tasklist" v-loading="loading">
                <!-- ===============================头部筛选区=================================  -->
                <el-form ref="form" label-width="80px" class="search-form">
                    <el-row>
                        <el-col
                            :span="6"
                            v-if="statusTabActiveIndex=='0' || statusTabActiveIndex=='37'"
                            style="float:right;"
                        >
                            <el-form-item label="项目状态">
                                <el-select
                                    v-model="status"
                                    placeholder="请选择"
                                    style="width:140px !important;"
                                    @change="statusChange"
                                >
                                    <el-option label="全部" value="0"></el-option>
                                    <el-option label="报名中" value="3"></el-option>
                                    <el-option label="评选中" value="4"></el-option>
                                    <!-- <el-option label="待签署"
                                               value="5">
                                    </el-option>-->
                                    <el-option label="待托管资金" value="6"></el-option>
                                    <el-option label="工作中" value="7"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6" style="float:right;">
                            <el-form-item label="项目模式">
                                <el-select
                                    v-model="task_type"
                                    placeholder="请选择"
                                    style=" width:140px !important;"
                                    @change="typeChange"
                                >
                                    <el-option label="全部" value="0"></el-option>
                                    <el-option label="竞价项目" value="1"></el-option>
                                    <el-option label="比选项目" value="2"></el-option>
                                    <el-option label="日薪项目" value="3"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

                <el-table
                    ref="taskTable"
                    :data="tableData"
                    style="width: 100%"
                    tooltip-effect="light"
                    :row-key="resolveTableKey"
                    @expand-change="expendChange"
                >
                    <el-table-column
                        :show-overflow-tooltip="true"
                        align="center"
                        label="项目名称"
                        prop="task_name"
                    ></el-table-column>

                    <el-table-column
                        :show-overflow-tooltip="true"
                        align="center"
                        label="雇主"
                        prop="employer_name"
                    ></el-table-column>

                    <el-table-column
                        :show-overflow-tooltip="true"
                        align="center"
                        label="截止报名时间"
                        prop="end_time_value"
                    ></el-table-column>

                    <el-table-column
                        :show-overflow-tooltip="true"
                        align="center"
                        label="项目模式"
                        prop="task_type_value"
                    >
                        <template slot-scope="props">
                            <el-tag
                                class="tag-blue"
                                v-if="props.row.task_type==1"
                                v-text="props.row.task_type_value"
                            ></el-tag>
                            <el-tag
                                class="tag-green"
                                v-if="props.row.task_type==2"
                                v-text="props.row.task_type_value"
                            ></el-tag>
                            <el-tag
                                class="tag-yellow"
                                v-if="props.row.task_type==3"
                                v-text="props.row.task_type_value"
                            ></el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column
                        :show-overflow-tooltip="true"
                        align="center"
                        label="项目状态"
                        prop="status_value"
                    ></el-table-column>

                    <el-table-column
                        :show-overflow-tooltip="true"
                        align="center"
                        label="报名人数"
                        prop="enroll_count"
                        v-if="userInfo.info.user_type==2"
                    ></el-table-column>

                    <el-table-column
                        :show-overflow-tooltip="true"
                        type="expand"
                        class-name="hide-col"
                    >
                        <template slot-scope="props">
                            <div class="expend-cont-wraper">
                                <el-menu
                                    :default-active="statusNavActiveIndex"
                                    class="row-expend-nav"
                                    mode="horizontal"
                                >
                                    <!----只有【雇主】----->
                                    <template v-if="userInfo.info.user_type==2">
                                        <!--
                                   项目状态（-1.审核失败；1.草稿箱；2.待审核（=发布）；3.报名中（=审核通过）；4.评选中；5.待签署；6.待托管；7.工作中；（3-7=进行中）8.已完成；9.调解中；10.已结束）
                                        -->

                                        <!--
                                    雇主工作台按钮：
                                    待审核：项目预览
                                    报名中：待办事项、项目预览、项目资料、项目补遗、设为热门、结束项目
                                    评选中：待办事项、项目预览、项目资料、结束项目
                                    待签署：待办事项、项目预览、项目资料、合同签署、结束项目
                                    待托管：待办事项、项目预览、项目资料、查看合同、托管酬金、结束项目
                                    工作中：待办事项、项目预览、项目资料、查看合同、查看酬金、项目调解
                                    已完成：项目预览、项目资料
                                    调解中：项目预览、项目资料、调解情况
                                    已结束：项目预览、项目资料
                                        -->

                                        <template
                                            v-if="(props.row.status>=3 && props.row.status<=8) || props.row.status==9 || props.row.status==10"
                                        >
                                            <el-menu-item index="1">
                                                <router-link
                                                    :to="'/user/task/todo?tk='+props.row.tk"
                                                >
                                                    <i class="dot" v-if="props.row.noReadSchedule"></i>
                                                    <i
                                                        class="jk-iconkongzhitai-daibanshixiang icon"
                                                    ></i>
                                                    <p>待办事项</p>
                                                </router-link>
                                            </el-menu-item>
                                        </template>

                                        <!----所有状态都可看----->
                                        <el-menu-item @click="previewBtn(props.row.tk)" index="2">
                                            <i class="jk-icon-gongzuotaiyulan icon"></i>
                                            <p>项目预览</p>
                                        </el-menu-item>

                                        <!----只有待审核不可见----->
                                        <el-menu-item
                                            index="3"
                                            @click="viewFile(props.row.tk)"
                                            v-if="props.row.status>2"
                                        >
                                            <i class="jk-icon-gongzuotaiyulan icon"></i>
                                            <p>项目资料</p>
                                        </el-menu-item>

                                        <!-- <el-menu-item
                                            index="4"
                                            v-if="props.row.status == 3"
                                            @click="addendum(props.row.tk,props.row.annex_replenish)"
                                            >
                                            <i class="jk-icon-fabubuyiwenjian icon"></i>
                                            <p>项目补遗</p>
                                        </el-menu-item>-->

                                        <!-- <el-menu-item index="5" v-if="props.row.status == 3" @click="addHot(props.row)">
                                            <i class="jk-icon-jiaweiremen icon"></i>
                                            <p>设为热门</p>
                                        </el-menu-item>-->

                                        <!-- <el-menu-item index="6"
                                                      v-if="props.row.status == 5">
                                            <i class="jk-icon-gongzuotaiyulan icon"></i>
                                            <p>合同签署</p>
                                        </el-menu-item>

                                        <el-menu-item index="7"
                                                      v-if="props.row.status == 6 || props.row.status == 7">
                                            <i class="jk-icon-gongzuotaiyulan icon"></i>
                                            <p>查看合同</p>
                                        </el-menu-item>-->

                                        <!-- <el-menu-item index="8" v-if="props.row.status == 6">
                                            <i class="jk-iconkongzhitai-chexiaoshensu icon"></i>
                                            <p>托管酬金</p>
                                        </el-menu-item>-->

                                        <el-menu-item
                                            index="10"
                                            v-if="props.row.status == 7"
                                            @click="appeal(props.row.tk,props.row.status)"
                                        >
                                            <i class="jk-iconkongzhitai-shenqingtiaojie icon"></i>
                                            <p>项目调解</p>
                                        </el-menu-item>

                                        <el-menu-item
                                            index="11"
                                            v-if="props.row.status == 9 || (props.row.status == 10 && props.row.appeal_deal)"
                                            @click="appealDetail(props.row.tk)"
                                        >
                                            <i class="jk-iconkongzhitai-shenqingtiaojie icon"></i>
                                            <p>调解情况</p>
                                        </el-menu-item>

                                        <!-- <el-menu-item index="12" v-if="props.row.status==5">
                                            <i class="jk-iconkongzhitai-chexiaoshensu icon"></i>
                                            <p>撤销调解</p>
                                        </el-menu-item>-->

                                        <el-menu-item
                                            index="14"
                                            v-if="props.row.status == 1 || props.row.status == -1"
                                            @click="editTask(props.row.tk)"
                                        >
                                            <i class="jk-icon-gongzuotaibianji icon"></i>
                                            <p>编辑项目</p>
                                        </el-menu-item>

                                        <el-menu-item
                                            index="15"
                                            v-if="props.row.status == 1 || props.row.status == -1"
                                            @click="publishBtn(props.row.tk)"
                                        >
                                            <i class="jk-icon-gongzuotaifabu icon"></i>
                                            <p>发布项目</p>
                                        </el-menu-item>

                                        <el-menu-item
                                            index="16"
                                            v-if="props.row.status == 1 || props.row.status == -1"
                                            @click="del(props.row.tk)"
                                        >
                                            <i class="jk-iconkongzhitai-shanchu icon"></i>
                                            <p>删除项目</p>
                                        </el-menu-item>

                                        <el-menu-item
                                            index="3"
                                            @click="appraise(props.row.tk,userInfo.info.uk)"
                                            v-if="props.row.status == 8"
                                        >
                                            <i class="jk-icon-gongzuotaiyulan icon"></i>
                                            <p>项目评价</p>
                                        </el-menu-item>

                                        <!-- <el-menu-item index="13"
                                                      v-if="props.row.status >=3 && props.row.status <=6"
                                                      @click="addHot(props.row.tk,props.row.is_hot)">
                                            <i class="jk-icon-gongzuotaifabu icon"></i>
                                            <p>设为热门</p>
                                        </el-menu-item>-->

                                        <el-menu-item
                                            index="13"
                                            v-if="props.row.status >=3 && props.row.status <=6"
                                            @click="doHandleClickEndtask(props.row.tk)"
                                        >
                                            <i class="jk-iconkongzhitai-jieshu icon"></i>
                                            <p>结束项目</p>
                                        </el-menu-item>

                                        <el-menu-item
                                            index="17"
                                            v-if="props.row.status"
                                            class="pull-right"
                                            @click="(e)=>handleTableCellClick(e,props.row)"
                                        >
                                            <i class="jk-iconkongzhitai-shouqi icon upbtn-icon"></i>
                                            <p>收起</p>
                                        </el-menu-item>
                                    </template>

                                    <!----只有【极客】显示----->
                                    <template v-else>
                                        <template>
                                            <!----待办事项----->
                                            <el-menu-item
                                                index="1"
                                                v-if="(props.row.status>=3 && props.row.status<=8) || props.row.status==9 || props.row.status==10"
                                            >
                                                <router-link
                                                    :to="'/user/task/todo?tk='+props.row.tk"
                                                >
                                                    <i class="dot" v-if="props.row.noReadSchedule"></i>
                                                    <i
                                                        class="jk-iconkongzhitai-daibanshixiang icon"
                                                    ></i>
                                                    <p>待办事项</p>
                                                </router-link>
                                            </el-menu-item>

                                            <!----项目预览（所有状态都可看）----->
                                            <el-menu-item
                                                @click="previewBtn(props.row.tk)"
                                                index="2"
                                            >
                                                <i class="jk-icon-gongzuotaiyulan icon"></i>
                                                <p>项目预览</p>
                                            </el-menu-item>

                                            <!-- <el-menu-item index="8" v-if="props.row.status == 6">
                                                <i class="jk-iconkongzhitai-chexiaoshensu icon"></i>
                                                <p>托管履约保证金</p>
                                            </el-menu-item>-->

                                            <el-menu-item
                                                index="3"
                                                @click="viewFile(props.row.tk)"
                                                v-if="( userInfo.info.user_type == 2 && props.row.status > 2 ) || ( userInfo.info.user_type == 1 && userInfo.info.tk == props.row.uk && props.row.status>2)"
                                            >
                                                <i class="jk-icon-gongzuotaiyulan icon"></i>
                                                <p>项目资料</p>
                                            </el-menu-item>

                                            <el-menu-item
                                                index="10"
                                                v-if="props.row.status == 7"
                                                @click="appeal(props.row.tk,props.row.status)"
                                            >
                                                <i class="jk-iconkongzhitai-shenqingtiaojie icon"></i>
                                                <p>项目调解</p>
                                            </el-menu-item>

                                            <el-menu-item
                                                index="11"
                                                v-if="props.row.status == 9 || (props.row.status == 10 && props.row.appeal_deal && userInfo && userInfo.info.user_type == 2) || (props.row.status == 10 && props.row.appeal_deal && userInfo && userInfo.info.user_type == 1 && userInfo.info.tk == props.row.uk)"
                                                @click="appealDetail(props.row.tk)"
                                            >
                                                <i class="jk-iconkongzhitai-shenqingtiaojie icon"></i>
                                                <p>调解情况</p>
                                            </el-menu-item>

                                            <!-- <el-menu-item index="12" v-if="props.row.status==5">
                                                <i class="jk-iconkongzhitai-chexiaoshensu icon"></i>
                                                <p>撤销调解</p>
                                            </el-menu-item>-->

                                            <!-- <el-menu-item index="6"
                                                          v-if="props.row.status == 5">
                                                <i class="jk-icon-gongzuotaiyulan icon"></i>
                                                <p>合同签署</p>
                                            </el-menu-item>

                                            <el-menu-item index="7"
                                                          v-if="props.row.status == 6 || props.row.status == 7">
                                                <i class="jk-icon-gongzuotaiyulan icon"></i>
                                                <p>查看合同</p>
                                            </el-menu-item>-->

                                            <el-menu-item
                                                index="3"
                                                v-if="props.row.status == 8"
                                                @click="appraise(props.row.tk,userInfo.info.uk)"
                                            >
                                                <i class="jk-icon-gongzuotaiyulan icon"></i>
                                                <p>项目评价</p>
                                            </el-menu-item>
                                        </template>
                                    </template>
                                </el-menu>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        align="center"
                        label="操作"
                        prop="handles"
                        min-width="30"
                        class-name="handles-col"
                    >
                        <template slot-scope="props">
                            <el-badge
                                is-dot
                                class="item"
                                :hidden="!props.row.noReadSchedule || !props.row.noReadTask || !props.row.noReadAppeal"
                            >
                                <el-button
                                    type="primary"
                                    size="mini"
                                    class="down-rol-btn"
                                    @click="(e)=>handleTableCellClick(e,props.row)"
                                >工作台</el-button>
                            </el-badge>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="page-wraper">
                    <pagination
                        v-if="tableData"
                        :pageSize="pageSize"
                        :currentPage="currentPage"
                        :pageTotal="pageTotal"
                        @current-change="newPage"
                    ></pagination>
                </div>

                <!-- ===============================上传补遗信息=================================  -->
                <el-dialog
                    title="上传补遗信息"
                    :visible.sync="addFile"
                    width="640px"
                    center
                    class="uploadFile blue-top-border"
                >
                    <el-form>
                        <el-form-item>
                            <el-input type="textarea" placeholder="请填写补遗信息" v-model="addendumText"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="doAddendum">上传</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>

                <!-- ===============================加为热门项目 - 支付方式选择=================================  -->
                <el-dialog
                    class="blue-top-border"
                    title="加为热门项目"
                    :visible.sync="hotTask"
                    width="500px"
                    center
                >
                    <div class="pointCoupon-buy">
                        <p>支付点券</p>

                        <p>
                            当前点券余额：
                            <span>{{userInfo.info.points_surplus}} 点券</span>
                        </p>

                        <el-button type="primary" style="margin-top:6px;" @click="pay('D01')">立即支付</el-button>

                        <div class="other-buy">
                            <h5>其他支付方式</h5>
                            <ol class="buy-way">
                                <li @click="pay('Y01')">
                                    <span class="buy-icon">
                                        <i class="jk-icon-yuezhifu"></i>
                                    </span>
                                    <strong>余额支付</strong>
                                </li>

                                <li @click="pay('W01')">
                                    <span class="buy-icon">
                                        <i class="jk-icon-weixinzhifu"></i>
                                    </span>
                                    <strong>微信支付</strong>
                                </li>

                                <li @click="pay('A01')">
                                    <span class="buy-icon">
                                        <i class="jk-icon-zhifubaozhifu"></i>
                                    </span>
                                    <strong>支付宝支付</strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                </el-dialog>

                <!-- ===============================点券支付弹出框=================================  -->
                <el-dialog
                    class="blue-top-border subs-dialog subys-dialog"
                    title="点券支付"
                    :visible.sync="dianqPaydialog"
                    width="400px"
                    center
                    append-to-body
                >
                    <div class="notprov-box">
                        <ul class="hosting-list hostings-list">
                            <li class="hosting-item apl-item">
                                <span>支付点券</span>
                                <i class="warn">{{userInfo.config.hot_task_paypoint}} 点券</i>
                            </li>
                            <li class="hosting-item">
                                <span>支付密码</span>
                            </li>
                            <li class="hosting-item">
                                <el-input
                                    v-model="password"
                                    type="password"
                                    placeholder="请输入支付密码完成支付"
                                ></el-input>
                            </li>
                        </ul>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="doPay('D01')">确认支付</el-button>
                    </span>
                </el-dialog>

                <!-- ===============================余额支付弹出框=================================  -->
                <el-dialog
                    class="blue-top-border subs-dialog subys-dialog"
                    title="余额支付"
                    :visible.sync="yePayDialog"
                    width="400px"
                    center
                    append-to-body
                >
                    <div class="notprov-box">
                        <ul class="hosting-list hostings-list">
                            <li class="hosting-item apl-item">
                                <span>支付金额</span>
                                <i class="warn">{{userInfo.config.hot_task_paymoney}} 元</i>
                            </li>
                            <li class="hosting-item">
                                <span>支付密码</span>
                            </li>
                            <li class="hosting-item">
                                <el-input
                                    v-model="password"
                                    type="password"
                                    placeholder="请输入支付密码完成支付"
                                ></el-input>
                            </li>
                        </ul>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="doPay('Y01')">确认支付</el-button>
                    </span>
                </el-dialog>

                <!-- ===============================微信支付=================================  -->
                <el-dialog
                    class="blue-top-border"
                    title="微信支付"
                    :visible.sync="wxBuyBtn"
                    width="500px"
                    center
                    @close="closeDialog"
                    append-to-body
                >
                    <div class="pointCoupon-buy">
                        <p>支付金额</p>
                        <p class="price">
                            ￥
                            <strong>{{userInfo.config.hot_task_paymoney}}</strong>（本次）
                        </p>
                        <div class="buy-ewm">
                            <img :src="pay_image" />
                        </div>
                        <div class="guide">
                            请使用微信扫描
                            <br />二维码完成支付
                        </div>
                    </div>
                </el-dialog>

                <!-- ===============================支付宝支付=================================  -->
                <el-dialog
                    class="blue-top-border"
                    title="支付宝支付"
                    :visible.sync="zfbBuyBtn"
                    width="500px"
                    center
                    @close="closeDialog"
                    append-to-body
                >
                    <div class="pointCoupon-buy">
                        <p>支付金额</p>
                        <p class="price">
                            ￥
                            <strong>{{userInfo.config.hot_task_paymoney}}</strong>（本次）
                        </p>
                        <div class="buy-ewm">
                            <img :src="pay_image" />
                        </div>
                        <div class="guide">
                            请使用支付宝扫描
                            <br />二维码完成支付
                        </div>
                    </div>
                </el-dialog>

                <!-- ===============================撤销调解=================================  -->
                <el-dialog
                    title="撤销调解"
                    :visible.sync="cancelBtn"
                    width="400px"
                    center
                    class="signupdialog"
                >
                    <p class="tips">确认要撤销调解吗？</p>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="cancelBtn = false">取 消</el-button>
                        <el-button type="primary" @click="cancelBtn = false">确 定</el-button>
                    </span>
                </el-dialog>

                <!-- ===============================结束项目弹出框=================================  -->
                <el-dialog
                    class="red-top-border subs-dialog"
                    title="结束项目"
                    :visible.sync="handleClickEndtask"
                    width="640px"
                    center
                >
                    <div class="renson-box">
                        <el-form ref="form" :model="Endtaskform">
                            <el-form-item>
                                <p class="end-txt">
                                    如果您的需求发生改变，或是已不需要继续进行这个项目的招标，您可以选择结束项目，并在这里填写结束原因。如：“已找到合适的项目合作人”等。
                                    <span class="warn">注意：</span>如您已经向工程极客发出邀请，则需等待工程极客拒绝该邀请，或所发送邀请超时后，方可结束项目。
                                </p>
                            </el-form-item>
                            <el-form-item>
                                <el-input type="textarea" class="txtare" v-model="overTaskContent"></el-input>
                            </el-form-item>
                            <p class="end-txt warn">项目一旦结束将无法恢复，是否确定结束项目。</p>
                        </el-form>
                    </div>
                    <span slot="footer" class="dialog-footer news-back-wrap">
                        <el-button @click="overTask" style="color:#555;">结束</el-button>
                    </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "taskList",

    metaInfo() {
        return {
            title: "我的项目 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },

    components: { Pagination },

    data() {
        var localhostUriString = window.location.href;
        var curId = localhostUriString.split("tk=")[1];
        curId = curId ? curId : 0;
        return {
            curId: curId,
            tk: "",
            task_type: "0", //项目类型（1.竞价；2.比选；3.日薪）
            status: 0, // 代表事项的状态，0全部，1报名中，2，评选中，3，执行中，4，已完成，5调解中，6，已结束
            userType: 0,
            statusNavActiveIndex: "1", // 项目状态当前选择
            statusTabActiveIndex: "0", // 项目状态当前选择
            currentPage: 1, // 当前分页
            pageSize: 10, // 每页显示条数
            pageTotal: 1, // 总页数
            cgxCount: 0, //草稿箱数量
            password: "",
            addFile: false,

            handleClickEndtask: false, //项目结束对话框
            overTaskContent: "", //结束项目内容
            cancelBtn: false,

            tableData: [],
            Endtaskform: {
                reason: "",
                tk: 0
            },
            addendumId: 0,
            addendumText: "",
            pay_money: 0.0,
            pay_image: "",
            hot_payPoint: 0.0,
            hot_payMoney: 0.0,
            timer: "",
            loading: false,

            hotTaskTk: "", //设为热门项目 - 项目tk
            hotTask: false, //设为热门项目 - 支付类型选择对话框
            dianqPaydialog: false, //设为热门项目 - 点券支付对话框
            yePayDialog: false, //设为热门项目 - 余额支付对话框
            wxBuyBtn: false, //微信二维码支付弹出框
            zfbBuyBtn: false //支付宝二维码支付弹出框
        };
    },

    computed: {
        ...mapState(["userInfo"])
    },

    methods: {
        //加载项目列表数据
        loadListData() {
            var _this = this;
            _this.loading = true;
            _this.post(
                "/task/task/lists",
                {
                    type: parseInt(_this.task_type), //项目类型（1.竞价项目；2.比选项目；3.日薪项目）
                    status: parseInt(_this.status), //项目状态
                    page: _this.currentPage, //分页
                    limit: _this.pageSize
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.cgxCount = res.data.cgx_count; //草稿箱数量
                        _this.tableData = res.data.data;
                        _this.pageTotal = res.data.total;
                        _this.userType = res.data.user_type;
                        _this.tableData.map(function (v) {
                            if (v && v.tk == _this.curId) {
                                _this.handleTableCellClick({}, v);
                            }
                        });
                    }
                }
            );
        },

        //分页方法
        newPage(page) {
            this.currentPage = page;
            this.loadListData();
        },

        //状态筛选事件
        statusChange(e) {
            this.currentPage = 1;
            this.status = e;
            this.loadListData();
        },

        //类型筛选事件
        typeChange(e) {
            this.currentPage = 1;
            this.type = e;
            this.loadListData();
        },

        //删除项目事件
        del(tk) {
            let _this = this;
            this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    _this.post(
                        "/task/task/del",
                        {
                            tk: tk
                        },
                        function (res) {
                            _this.$message({
                                message: "删除成功",
                                type: "success"
                            });
                            _this.loadListData(); //加载列表
                        }
                    );
                })
                .catch(() => { });
        },

        //打开项目结束对话框
        doHandleClickEndtask(tk) {
            this.tk = tk;
            this.overTaskContent = "";
            this.handleClickEndtask = true;
        },
        //项目结束事件
        overTask() {
            let _this = this;
            if (!_this.overTaskContent) {
                _this.$message.error(
                    "项目结束说明不能为空，请填写结束项目的详细说明。"
                );
                return false;
            }
            _this.post(
                "/task/task/over_task",
                {
                    tk: _this.tk,
                    over_content: _this.overTaskContent
                },
                res => {
                    if (res.code == 200) {
                        _this.$message.success("结束项目操作成功");
                        _this.overTaskContent = ""; //情况结束项目内容
                        _this.handleClickEndtask = false; //关闭结束项目对话框
                        _this.statusTabToggle(); //刷新数据
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("项目结束失败");
                        }
                    }
                }
            );
        },

        resolveTableKey(row) {
            //给tab的row设置row-key，用于展开和收起时只能展开一个
            return row.tk;
        },

        //表格展开事件
        expendChange(row, expandedRows) {
            for (var i = 0; i < expandedRows.length; i++) {
                if (expandedRows[i]["tk"] == row.tk) {
                    sessionStorage.setItem(
                        "task_list_table_index",
                        JSON.stringify(expandedRows[i])
                    );
                    this.$refs.taskTable.toggleRowExpansion(
                        expandedRows[i],
                        true
                    );
                } else {
                    this.$refs.taskTable.toggleRowExpansion(
                        expandedRows[i],
                        false
                    );
                }
            }
        },

        //工作台切换按钮事件
        handleTableCellClick(e, row) {
            this.$refs.taskTable.toggleRowExpansion(row);
        },

        addendum(id, text) {
            this.addFile = true;
            this.addendumId = id;
            this.addendumText = text;
        },

        doAddendum() {
            var _this = this;
            this.post(
                "/task/task/addendum",
                {
                    tk: _this.addendumId,
                    annex_replenish: _this.addendumText
                },
                function (res) {
                    _this.$message({
                        message: "处理成功！",
                        type: "success"
                    });
                    _this.addFile = false;
                    _this.loadListData();
                }
            );
        },

        ToDoList(id) {
            //待办事项页面跳转
            this.$router.push("/employeeuc/todos/todosStatus/all?tk=" + id);
        },

        lookDetail(id) {
            this.$router.push("/task/detail?tk=" + id); //查看详情页面跳转
        },

        previewBtn(id) {
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/task/detail?view=1&tk=" + id); //预览点击跳转页面
        },

        editTask(id) {
            this.$router.push("/task/publish?tk=" + id); //编辑点击跳转页面
        },

        appealBtn() {
            this.$router.push("/appealform"); //申请调解点击跳转页面
        },

        appeal(tk, task_status) {
            if (task_status == 7 || task_status == 9) {
                this.$router.push("/user/task/appeal?tk=" + tk);
            } else {
                this.$message.success(
                    "该项目状态不是工作中，不可提交调解申请！"
                );
            }
        },

        //申请情况
        appealDetail(tk) {
            this.$router.push("/user/task/appeal_detail?tk=" + tk);
        },

        //点击设为热门项目
        addHot(tk, is_hot) {
            if (is_hot == 1) {
                this.$message.error("该项目已经是热门项目");
                return false;
            }
            console.log(is_hot);
            var _this = this;
            _this.hotTaskTk = tk;
            _this.hotTask = true;
        },

        publishBtn(tk) {
            var _this = this;
            this.post(
                "/task/task/publish",
                {
                    tk: tk
                },
                function (res) {
                    if (res.code == 200) {
                        //发布成功提示
                        _this.$message({
                            message:
                                "恭喜您的项目已经成功提交，审核完成后将在平台正式发布。",
                            type: "success"
                        });
                        _this.loadListData();
                    } else {
                        _this.$message({
                            message: res.msg,
                            type: "error"
                        });
                    }
                }
            );
        },

        closeDialog() {
            this.wxBuyBtn = false;
            this.zfbBuyBtn = false;
            clearInterval(_this.timer);
        },

        go_to(name) {
            this.$router.push({ name: name });
        },

        //选择支付方式
        pay(pay_type = "") {
            var _this = this;

            //微信支付
            if (pay_type == "W01") {
                _this.hotTask = false; //关闭选择支付方式对话框
                _this.doPay(pay_type);
            }

            //支付宝支付
            if (pay_type == "A01") {
                _this.hotTask = false; //关闭选择支付方式对话框
                _this.doPay(pay_type);
            }

            if (pay_type == "D01" || pay_type == "Y01") {
                if (!_this.userInfo.info.has_pay_password) {
                    this.$confirm(
                        "不能进行此操作，您还没有设置支付密码，是否现在设置？",
                        "温馨提示",
                        {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消"
                        }
                    )
                        .then(() => {
                            //存储当前值
                            sessionStorage.setItem(
                                "currentPageUrl",
                                document.location.pathname +
                                document.location.search
                            );
                            _this.$router.push(
                                "/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码"
                            );
                        })
                        .catch(() => { });
                    return false;
                }
            }

            //点券支付
            if (pay_type == "D01") {
                _this.hotTask = false; //关闭选择支付方式对话框
                _this.dianqPaydialog = true; //打开点券支付对话框
            }

            //余额支付
            if (pay_type == "Y01") {
                _this.hotTask = false; //关闭选择支付方式对话框
                _this.yePayDialog = true; //打开余额支付对话框
            }
        },

        //支付操作
        doPay(pay_type = "") {
            let _this = this;
            if (pay_type == "") {
                _this.$message.error("支付类型不能为空");
                return false;
            }

            if (pay_type == "D01" || pay_type == "Y01") {
                if (_this.password == "") {
                    _this.$message.error("支付密码不能为空");
                    return false;
                }
            }

            _this.loading = true;

            //生成订单
            _this.post(
                "/task/task/hot_task",
                {
                    tk: _this.hotTaskTk,
                    pay_type: pay_type
                },
                function (res) {
                    if (res.code == 200) {
                        _this.order_sn = res.data.order_no;

                        //支付订单
                        _this.post(
                            "/pay/pay/pay",
                            {
                                order_no: res.data.order_no,
                                password: _this.password
                            },
                            function (res1) {
                                _this.loading = false;
                                if (res1.code == 200) {
                                    switch (pay_type) {
                                        case "A01": //支付宝支付
                                            _this.timer = setInterval(() => {
                                                _this.isPayCheck();
                                            }, 2000);
                                            _this.pay_image = res1.data.img;
                                            _this.zfbBuyBtn = true;
                                            break;
                                        case "W01": //微信支付
                                            _this.timer = setInterval(() => {
                                                _this.isPayCheck();
                                            }, 2000);
                                            _this.pay_image = res1.data.img;
                                            _this.wxBuyBtn = true;
                                            break;
                                        case "Y01": //余额支付
                                            _this.$message.success("设置成功");
                                            _this.yePayDialog = false; //关闭余额支付对话框
                                            _this.loadListData(); //刷新列表数据
                                            break;
                                        case "D01": //点券支付
                                            _this.$message.success("设置成功");
                                            _this.dianqPaydialog = false; //关闭点券支付对话框
                                            _this.loadListData(); //刷新列表数据
                                            break;
                                        default:
                                            break;
                                    }
                                } else {
                                    if (res1.msg) {
                                        _this.$message.error(res1.msg);
                                    } else {
                                        _this.$message.error("订单支付失败");
                                    }
                                    return false;
                                }
                            }
                        );
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("订单生成失败");
                        }
                        return false;
                    }
                }
            );
        },

        isPayCheck() {
            let _this = this;
            _this.post(
                "/pay/pay/is_pay",
                { order_no: _this.order_sn },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success("设置成功");
                        clearInterval(_this.timer);
                        setInterval(() => {
                            location.reload();
                        }, 2000);
                    }
                    if (_this.wxBuyBtn == false && _this.zfbBuyBtn == false) {
                        clearInterval(_this.timer);
                    }
                }
            );
        },

        //切换tab事件
        statusTabToggle() {
            let status = this.$route.query.status;
            if (status) {
                if (status >= 3 && status <= 7) {
                    this.statusTabActiveIndex = "37"; //进行中项目
                } else {
                    this.statusTabActiveIndex = status; //非进行中项目
                }

                this.status = status;
                this.loadListData();

                if (status == 37) {
                    this.status = "0";
                }
            }
        },

        //项目评价
        appraise(tk, uk) {
            let _this = this;
            _this.post("/task/task/is_appraise", { tk: tk }, function (res) {
                if (res.code == 200) {
                    if (res.data.is_appraise == 1) {
                        _this.$message.error("您已评价过，不能再次评价！");
                        return false;
                    } else {
                        _this.$router.push(
                            "/user/task/appraise?tk=" + tk + "&uk=" + uk
                        );
                    }
                }
            });
        },

        //项目资料
        viewFile(tk) {
            this.$router.push("/user/task/file?tk=" + tk);
        }
    },

    created() {
        this.statusTabToggle();
    },

    mounted() {
        //设置表格展开和显示状态
        let table_index = sessionStorage.getItem("task_list_table_index");
        if (table_index) {
            this.$refs.taskTable.toggleRowExpansion(
                JSON.parse(table_index),
                true
            );
        }
    },

    watch: {
        $route: {
            handler: function (val, oldVal) {
                this.statusTabToggle(); //切换项目状态tab事件
            },
            deep: true // 深度观察监听
        }
    }
};
</script>

<style lang="less">
@import "../../../styles/tasks.less";
.before-line {
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0px;
        top: 3px;
        bottom: 0;
        width: 1px;
        height: 20px;
        margin: auto;
        background: #ccc;
    }
}
.u-tool-tip {
    padding: 5px 10px 5px 15px;
    background: #409eff;
    color: #fff;
    line-height: 1.5;
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    height: 28px;
    margin: auto;
    font-size: 12px;
    border-radius: 4px;
}
.u-tool-tip:before {
    content: "";
    position: absolute;
    left: -3px;
    top: 11px;
    width: 6px;
    height: 6px;
    background: #409eff;
    transform: rotate(135deg);
    z-index: 999;
}
</style>


